import React from 'react';

import { getImageLoader } from '@utils/imageLoader';
import cx from 'clsx';
import Image from 'next/image';
import type { OwnProps } from 'ProductItemImage';

import Styles from './ProductItemImage.module.scss';

const CentraProductPlaceholder = '/images/mockedProduct.png';
const SRC_NAME = 'standard';
const IMAGE_WIDTH = 40;
const IMAGE_HEIGHT = 57;
const mapOptionsToSizeName = (width: number) =>
  ({
    320: 'small_x2',
    360: 'medium',
    380: 'standard',
    640: 'big',
    720: 'medium_x2',
    800: 'standard_x2',
    1080: 'big_x2',
  })[width] || '';
const imageLoader = getImageLoader(mapOptionsToSizeName, SRC_NAME);

export const ProductItemImage: React.FC<OwnProps> = ({
  media,
  alt,
  uri,
  sku,
  mockedImage = CentraProductPlaceholder,
}) => {
  const standard = Array.isArray(media.new_standard) ? media.new_standard[0] : mockedImage;
  const standardHover = Array.isArray(media.new_standard) ? media.new_standard[1] : null;
  const blur = Array.isArray(media.blur) && media.blur[0] ? media.blur[0] : standard;
  const blurHover = Array.isArray(media.blur) && media.blur[1] ? media.blur[1] : blur;

  const firstImageClassName = cx(Styles.firstImage, {
    [Styles.noHover]: media?.standard?.length <= 1,
  });

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.inner}>
        {standard && (
          <div className={firstImageClassName}>
            <Image
              src={standard}
              layout="responsive"
              loader={imageLoader}
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
              alt={alt}
              itemProp="itemListElement"
              data-url={uri}
              data-sku={sku}
              placeholder="blur"
              blurDataURL={blur}
              unoptimized
            />
          </div>
        )}
        {standardHover && (
          <div className={Styles.secondImage}>
            <Image
              src={standardHover}
              layout="responsive"
              loader={imageLoader}
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
              alt={alt}
              itemProp="itemListElement"
              data-url={uri}
              data-sku={sku}
              placeholder="blur"
              blurDataURL={blurHover}
              unoptimized
            />
          </div>
        )}
      </div>
    </div>
  );
};
