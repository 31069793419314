import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ProductItem } from '@components/components';
import { Divider } from '@components/components/MainProductsGrid/components';
import CentraCategoryPageContext from '@components/contexts/CentraCategoryPageContext';
import type { FilterProduct } from '@components/renderers/ContentfulPagesRenderer/components/CentraProducts/CentraProducts';
import { trackGA4ProductList } from '@core-analytics/getAnalyticsFunctions';
import { getProductListJSON } from '@core-analytics/schemas';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { getProductListSchemaJSON } from '../../../core-components/schema';

import { chunk } from './ProductGrid.utils';
import type { OwnProps } from './types';
import { addDividers } from './utils';

import Styles from './ProductGrid.module.scss';

export const ProductGrid: React.FC<OwnProps> = props => {
  const { parameters } = props;
  const { productItemClassName, listName, ...rest } = parameters;
  const centraContext = React.useContext(CentraCategoryPageContext);
  const { products, uri, maxItems, categoryPageConfig, noHoverAction, mockedImage, filteredProducts } = centraContext;
  const after = categoryPageConfig?.dividerAfterHowManyImages || 6;
  const dividers = categoryPageConfig?.dividers || [];
  const { formatMessage } = useIntl();

  const [productsAnalytics, setProductsAnalytics] = useState('');
  const productsSchema = products && getProductListSchemaJSON(products, uri!);

  if (!products) {
    return null;
  }

  const productsChunks = chunk(filteredProducts, after);
  const contentBlocks = addDividers(productsChunks, dividers, after);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const productListName = useRef('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const productListNameRef = useRef();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { asPath } = useRouter();

  const getListName = (name: string): string => {
    const chars = 'abcdefghi';
    let result = '';
    for (let i = 0; i < 5; i += 1) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return name || `list${result}`;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (products?.length > 0) {
      productListName.current = getListName(listName);
      const analytics = getProductListJSON(
        products,
        productListName.current.replace('/', ' ').toLocaleLowerCase() + asPath,
        productListName.current.replace('/', ' '),
      );
      setProductsAnalytics(analytics);
    }
  }, [products]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (productsAnalytics && productsAnalytics.length > 0) {
      trackGA4ProductList(true, productListNameRef.current);
    }
  }, [productsAnalytics]);

  const renderContent = () => {
    return contentBlocks.map((cont, i) => {
      if (cont.type === 'products') {
        const key = i.toString();
        const shownProducts = maxItems ? cont.data.slice(0, maxItems) : cont.data;

        return (
          <Fragment key={key}>
            <div data-testid="product-grid" className={Styles.grid}>
              {shownProducts.map((product: any, i: number) => {
                const key = `${i}-${Date.now().toString()}`;
                const productItemProps = {
                  product,
                  key,
                  className: productItemClassName,
                  noHoverAction,
                  mockedImage,
                  ...rest,
                };
                // eslint-disable-next-line react/jsx-key
                return <ProductItem {...productItemProps} />;
              })}
            </div>
          </Fragment>
        );
      }

      if (cont.type === 'divider') {
        const { asDividerInWhichPages } = cont.data;
        const availableOnArray = asDividerInWhichPages?.length > 0 ? asDividerInWhichPages.split(',') : null;
        const key = `${cont.type}-${cont.id}`;
        if (availableOnArray === null) {
          return (
            <div className={Styles.dividerWrapper} key={key}>
              <Divider data={cont.data} />
            </div>
          );
        }
      }

      return null;
    });
  };

  const renderNoItems = () => {
    return (
      <div className={Styles.noProducts}>
        <h2>{formatMessage({ id: 'productGrid.noProducts' })}</h2>
      </div>
    );
  };

  return (
    <>
      <Head>
        <script type="application/ld+json" data-schema="product-list">
          {productsSchema && productsSchema.length > 0 && productsSchema}
        </script>
      </Head>
      {filteredProducts.length === 0 && renderNoItems()}
      {renderContent()}
      {productsAnalytics && productsAnalytics.length > 0 && (
        <div ref={productListNameRef} data-analytics-category={productListName.current}>
          <script data-analytics="product-list" type="application/json">
            {productsAnalytics}
          </script>
        </div>
      )}
    </>
  );
};
